



















  import { Component, Prop, Vue } from 'vue-property-decorator'

  @Component
  export default class OneButtonToggle extends Vue {
    handle: any = {
      diameter: 17,
      distance: 17,
      color: '#fff',
      borderRadius: '50%',
    }

    track: any = {
      color: '#ccc',
      width: 40,
      height: 20,
      borderWidth: 0,
      borderRadius: '34px',
    }

    @Prop({
      required: true,
      type: Boolean,
    })
    value!: boolean

    @Prop({
      required: true,
      type: String,
    })
    activeText!: string

    @Prop({
      required: true,
      type: String,
    })
    inActiveText!: string

    @Prop({
      type: Boolean,
      default: false,
    })
    isText!: boolean

    get styles() {
      return {
        '--handle-diameter': `${this.handle.diameter}px`,
        '--handle-color': this.handle.color,
        '--handle-border-radius': this.handle.borderRadius,
        '--handle-distance': `${this.handle.distance + 2}px`,
        '--track-color': this.track.color,
        '--track-width': `${this.track.width}px`,
        '--track-height': `${this.track.height}px`,
        '--track-border-width': `${this.track.borderWidth}px`,
        '--track-border-radius': this.track.borderRadius,
      }
    }
  }
